import React, { useContext, useState } from "react";
import axios from "axios";
import { Button, Form, InputGroup, Row, Col, Modal } from "react-bootstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { API_URL } from "utils/base-url";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import WeekReportContext, {
  humanizeAttributes,
} from "../../contexts/WeekReportContext";
import ErrorMessages from "components/Forms/ErrorMessages";
import CurrencyInput from "react-currency-input-field";

function editWeekReport(props) {
  const weekReportContext = useContext(WeekReportContext);
  const [errors, setErrors] = useState(null);
  const refresh = props.refresh;
  const setModal = props.setModal;
  const handleClear = props.handleClear;
  const show = props.show;

  const handleClose = () => {
    setErrors(null);
    setModal(false);
  };

  const handleUpdate = () => {
    setErrors(null);
    axios
      .put(API_URL + "week_reports/" + weekReportContext.state.id, {
        week_report: weekReportContext.state,
      })
      .then((resp) => handleResult())
      .catch((error) => {
        setErrors(error.response.data);
        return error;
      });
  };

  function handleResult() {
    setErrors(null);
    refresh(weekReportContext.state.weekly_statement_id);
    handleClear();
    setModal(false);
  }

  return (
    <>
      <Row></Row>
      <Modal
        show={props.modal}
        onHide={handleClose}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Alterar Hostess {weekReportContext.state.code} -{" "}
            {weekReportContext.state.anchor_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="WeeklyStatementForm">
            <ErrorMessages
              errors={errors}
              humanizeAttributes={humanizeAttributes}
            ></ErrorMessages>
            <Row>
              <Col md="4">
                <Form.Group>
                  <label>{humanizeAttributes["bonus_coins"]}</label>
                  <Form.Control
                    placeholder="Digite aqui..."
                    type="number"
                    disabled={show}
                    value={weekReportContext.state.bonus_coins}
                    onChange={(e) =>
                      weekReportContext.setState({
                        ...weekReportContext.state,
                        bonus_coins: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>{humanizeAttributes["bonus_money"]}</label>
                  <Form.Control
                    placeholder="Digite aqui..."
                    type="number"
                    disabled={show}
                    value={weekReportContext.state.bonus_money}
                    onChange={(e) =>
                      weekReportContext.setState({
                        ...weekReportContext.state,
                        bonus_money: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>{humanizeAttributes["penalty"]}</label>
                  <Form.Control
                    placeholder="Digite aqui..."
                    type="number"
                    disabled={show}
                    value={weekReportContext.state.penalty}
                    onChange={(e) =>
                      weekReportContext.setState({
                        ...weekReportContext.state,
                        penalty: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {!show && (
            <Button variant="primary" onClick={handleUpdate}>
              Atualizar
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default editWeekReport;
