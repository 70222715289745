import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "utils/base-url";
import UserForm from "views/Users/Form";
import UserContext, { data, initialState, labels } from "../../contexts/UserContext";
import { confirm } from "components/Forms/DeleteConfirmation";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Table,
} from "react-bootstrap";


export default function User() {
  const [state, setState] = useState(data);
  const [objects, setObjects] = useState([]);
  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(false);
  const indexRoute = "users";

  // *********************** MÉTODOS CRUD ************************* //

  const getObjects = async () => {
    try {
      const { data } = await axios.get(API_URL + "/" + indexRoute);
      setObjects(data);
    } catch (e) {
      //dispatch({ type: "SET_ERROR", payload: "Something went wrong." });
      console.error(e);
    }
  };

  function handleEdit(user) {
    setShow(false);
    setState(user);
    setModal(true);
  }

  const handleShow = (user) => {
    setShow(true);
    setState(user);
    setModal(true);
  };

  const handleNew = () => {
    setShow(false);
    handleClear();
    setModal(true);
  };

  function handleClear() {
    setState(initialState);
  }

  const handleDelete = async (id) => {
    if (await confirm("Deseja realmente excluir o registro?")) {
      const result = { error: false };

      try {
        await axios.delete(`${API_URL}/${indexRoute}/${id}`);
        getObjects();
      } catch (e) {
        result.error = true;
        console.error(e);
      }

      return result;
    }
  };

  // *********************** MÉTODOS CRUD ************************* //

  useEffect(() => {
    getObjects();
  }, []);  

  return (
    <>
      <UserContext.Provider value={{ state, setState }}>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Usuários</Card.Title>
                </Card.Header>
                <Card.Body className="table-responsive p-0">
                  <Col className="ml-auto" md="3">
                    <div style={{ textAlign: "right", marginRight: "30px" }}>
                      <Button variant="default" onClick={handleNew}>
                        <i className="nc-icon nc-simple-add icon-bold" /> Novo
                        Usuário
                      </Button>
                    </div>
                  </Col>
                  <UserForm
                    refresh={getObjects}
                    setModal={setModal}
                    show={show}
                    handleClear={handleClear}
                    modal={modal}
                  ></UserForm>
                  <Row>
                    <Col md="12">
                      <Table className="table-hover table-striped w-full">
                        <thead>
                          <tr>
                          {labels && labels.map((label,i) => {
                              return(
                              <th key={i}>{label}</th>
                              )
                            }) }
                            <th className="text-center">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {objects &&
                            objects.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{data.username}</td>
                                  <td>{data.email}</td>                                  
                                  <td className="td-actions text-center">
                                    <OverlayTrigger
                                      href="#"
                                      onClick={(e) => handleShow(data)}
                                      overlay={
                                        <Tooltip id="tooltip-48903503">
                                          View Profile..
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        className="btn-link btn-xs"
                                        href="#"
                                        onClick={(e) => handleShow(data) }
                                        variant="info"
                                      >
                                        <i className="fas fa-user"></i>
                                      </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      href="#"
                                      onClick={(e) => handleEdit(data)}
                                      overlay={
                                        <Tooltip id="tooltip-981231696">
                                          Editar
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        className="btn-link btn-xs"
                                        href="#"
                                        onClick={(e) => handleEdit(data)}
                                        variant="success"
                                      >
                                        <i className="fas fa-edit"></i>
                                      </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      href="#"
                                      onClick={(e) => handleDelete(data.id)}
                                      overlay={
                                        <Tooltip id="tooltip-255158527">
                                          Remover
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        className="btn-link btn-xs"
                                        href="#"
                                        onClick={() => handleDelete(data.id)}
                                        variant="danger"
                                      >
                                        <i className="fas fa-times"></i>
                                      </Button>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </UserContext.Provider>
    </>
  );
}
