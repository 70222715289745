import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, InputGroup, Row, Col, Modal } from "react-bootstrap";
import Select from "react-select";
import { API_URL } from "utils/base-url";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AgencyContext, {
  humanizeAttributes,
} from "../../contexts/AgencyContext";
import ErrorMessages from "components/Forms/ErrorMessages";

function newAgency(props) {
  const agencyContext = useContext(AgencyContext);
  const [errors, setErrors] = useState(null);
  const [apps, setApps] = useState({});
  const refresh = props.refresh;
  const setModal = props.setModal;
  const handleClear = props.handleClear;
  const show = props.show;

  const handleClose = () => {
    setErrors(null);
    setModal(false);
  };
  const handleSubmit = () => {
    setErrors(null);
    axios
      .post(API_URL + "agencies", {
        agency: agencyContext.state,
      })
      .then((resp) => handleResult())
      .catch((error) => {
        setErrors(error.response.data);
        return error;
      });
  };

  const handleUpdate = () => {
    setErrors(null);
    axios
      .put(API_URL + "agencies/" + agencyContext.state.id, {
        agency: agencyContext.state,
      })
      .then((resp) => handleResult())
      .catch((error) => {
        setErrors(error.response.data);
        return error;
      });
  };

  function handleResult() {
    setErrors(null);
    refresh();
    handleClear();
    setModal(false);
  }

  const listApps = async () => {
    try {
      const { data } = await axios.get(API_URL + "/" + "apps");
      setApps(data);
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    listApps();
  }, []);

  return (
    <>
      <Row></Row>
      <Modal show={props.modal} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nova Agência</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="AgencyForm">
            <ErrorMessages
              errors={errors}
              humanizeAttributes={humanizeAttributes}
            ></ErrorMessages>
            <Form.Group>
              <label>Aplicativo</label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                name="appId"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={agencyContext.state.app}
                onChange={(e) =>
                  agencyContext.setState({
                    ...agencyContext.state,
                    app_id: e.id,
                    app: e
                  })
                }
                options={apps}
                placeholder="Selecione o Aplicativo"
              />
            </Form.Group>
            <Form.Group>
              <label>Agência</label>
              <Form.Control
                placeholder="Digite aqui..."
                type="text"
                disabled={show}
                name="name"
                value={agencyContext.state.name}
                onChange={(e) =>
                  agencyContext.setState({
                    ...agencyContext.state,
                    name: e.target.value,
                  })
                }
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <label>Responsável</label>
              <Form.Control
                placeholder="Digite aqui..."
                type="text"
                disabled={show}
                value={agencyContext.state.contact_name}
                onChange={(e) =>
                  agencyContext.setState({
                    ...agencyContext.state,
                    contact_name: e.target.value,
                  })
                }
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <label>Fone</label>
              <PhoneInput
                country={"br"}
                value={agencyContext.state.contact_phone}
                disabled={show}
                inputStyle={{ width: "100%" }}
                onChange={(phone) =>
                  agencyContext.setState({
                    ...agencyContext.state,
                    contact_phone: phone,
                  })
                }
              />
            </Form.Group>
            <Form.Group>
              <label>Email</label>
              <Form.Control
                placeholder="Digite aqui..."
                type="text"
                disabled={show}
                value={agencyContext.state.email}
                onChange={(e) =>
                  agencyContext.setState({
                    ...agencyContext.state,
                    email: e.target.value,
                  })
                }
              ></Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {!show && (
            <Button
              variant="primary"
              onClick={
                agencyContext.state.id === "" ? handleSubmit : handleUpdate
              }
            >
              {agencyContext.state.id === "" ? "Salvar" : "Atualizar"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default newAgency;
