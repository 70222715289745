import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import { API_URL } from "utils/base-url";
import NotificationAlert from "react-notification-alert";
import moment from "moment";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Table,
} from "react-bootstrap";
import WeeklyStatementContext, {
  humanizeAttributes,
} from "../../contexts/WeeklyStatementContext";

function importHostesses(props) {
  const weeklyStatementContext = useContext(WeeklyStatementContext);
  const [file, setFile] = useState();
  const [missedHostess, setMissedHostess] = useState([]);
  const [importedHostess, setImportedHostess] = useState([]);
  const notificationAlertRef = React.useRef(null);
  const refresh = props.refresh;

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = () => {
    setMissedHostess([]);
    setImportedHostess([]);
    var error = null;
    if (!file) {
      handleMsg("Arquivo não selecionado", "danger");
    } else {
      const formData = new FormData();

      formData.append("file", file);

      axios
        .post(API_URL + "/hostesses/import_hostesses", formData)
        .then((resp) => handleResult(resp))
        .catch((error) => {
            console.log(error);
          handleMsg(error.data.result.toString(), "danger");
          return error;
        });
    }
  };

  function handleResult(resp) {
    if (resp.data.success) {
      setMissedHostess(resp.data.result.missed_hostess);
      setImportedHostess(resp.data.result.imported_hostess);
      handleMsg("Arquivo Importado com Sucesso!", "info");
      refresh();
    } else {
      handleMsg(resp.data.result.toString(), "danger");
    }
  }

  function handleMsg(msg, type) {
    notificationAlertRef.current.notificationAlert({
      place: "tr",
      message: (
        <div>
          <div style={{ margin: "10px" }}>{msg}</div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    });
  }

  useEffect(() => {
    
  }, []);

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Card className="card-stats">
        <Card.Header>
          <Card.Title as="h4">
            Importar Hostesses   
          </Card.Title>
        </Card.Header>
        <hr></hr>
        <Card.Body>
          <Row>
            <Col xs="2">
              <div className="icon-big text-center icon-success">
                <i className="nc-icon nc-bullet-list-67 text-success"></i>
              </div>
            </Col>
            <Col xs="10">
              <Form id="ImportDailyForm">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Arquivo</Form.Label>
                  <Form.Control type="file" onChange={handleFileChange} />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col xs="2"></Col>
            <Col xs="10">
              <Button variant="primary" onClick={handleSubmit}>
                Importar
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      {missedHostess.length > 0 ? (
        <>
          <Card>
            <Card.Header>
              <Card.Title as="h4">Não importados</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs="12">
                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>Linha</th>
                        <th>Telefone</th>
                        <th>Hostess</th>                       
                        <th>Contato</th>
                        <th>Erros</th>
                      </tr>
                    </thead>
                    <tbody>
                      {missedHostess.map((data) => {
                          return (
                            <tr key={data.linha}>
                              <td>{data.linha}</td>
                              <td>{data.phone}</td>
                              <td>{data.name}</td>
                              <td>{data.nickname}</td>
                              <td>{data.errors}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </> 
      ) : (
        <></>
      )}

{importedHostess.length > 0 ? (
        <>
          <Card>
            <Card.Header>
              <Card.Title as="h4">Importados</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs="12">
                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>Linha</th>
                        <th>Telefone</th>
                        <th>Hostess</th>                       
                        <th>Contato</th>
                      </tr>
                    </thead>
                    <tbody>
                      {importedHostess.map((data) => {
                          return (
                            <tr key={data.linha}>
                              <td>{data.linha}</td>
                              <td>{data.phone}</td>
                              <td>{data.name}</td>
                              <td>{data.nickname}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </> 
      ) : (
        <></>
      )}
    </>
  );
}
export default importHostesses;
