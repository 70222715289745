import React from "react";

// react-bootstrap components
import {
  Alert,
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Modal,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function ErrorMessages(props) {
  const errors = props.errors;
  const humanizeAttributes = props.humanizeAttributes || {};
  return (
    <>
    {errors &&
      <Alert variant="danger">
        <button
          aria-hidden={true}
          className="close"
          data-dismiss="alert"
          type="button"
        >
          <i className="nc-icon nc-simple-remove"></i>
        </button>
        <span>
          <b>Atenção - Corrija os erros abaixo</b>
          
            { Object.keys(errors).map(function (keyName, keyIndex) {
              return (
                <li key={keyIndex}>
                  {humanizeAttributes[keyName]} - {errors[keyName]}
                </li>
              );
            })}
        </span>
      </Alert>
    }
    </>
  );
}
export default ErrorMessages;
