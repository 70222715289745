import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, InputGroup, Row, Col, Modal } from "react-bootstrap";
import ReactDatetime from "react-datetime";
import moment from "moment";
import "moment/locale/pt-br";
import Select from "react-select";
import { API_URL } from "utils/base-url";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactCountryFlag from "react-country-flag";
import { ReactCountryDropdown } from "components/react-country-dropdown/dist";
import "components/react-country-dropdown/dist/index.css";

import HostessesContext, {
  humanizeAttributes,
  dataAccountType,
  dataPixType,
  dataMaritalStatus,
  dataStatusType,
  dataBank
} from "../../contexts/HostessContext";
import ErrorMessages from "components/Forms/ErrorMessages";

function newHostesses(props) {
  const hostessContext = useContext(HostessesContext);
  const [errors, setErrors] = useState(null);
  const [apps, setApps] = useState({});
  const refresh = props.refresh;
  const setModal = props.setModal;
  const handleClear = props.handleClear;
  const show = props.show;

  const handleClose = () => {
    setErrors(null);
    setModal(false);
  };
  const handleSubmit = () => {
    setErrors(null);
    axios
      .post(API_URL + "hostesses", {
        hostess: hostessContext.state,
      })
      .then((resp) => handleResult())
      .catch((error) => {
        setErrors(error.response.data);
        return error;
      });
  };

  const handleUpdate = () => {
    setErrors(null);
    axios
      .put(API_URL + "hostesses/" + hostessContext.state.id, {
        hostess: hostessContext.state,
      })
      .then((resp) => handleResult())
      .catch((error) => {
        setErrors(error.response.data);
        return error;
      });
  };

  function handleResult() {
    setErrors(null);
    refresh();
    handleClear();
    setModal(false);
  }

  const listApps = async () => {
    try {
      const { data } = await axios.get(API_URL + "/" + "apps");
      setApps(data);
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    listApps();
  }, []);

  return (
    <>
      <Row></Row>
      <Modal
        show={props.modal}
        onHide={handleClose}
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Nova Hostess</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="HostessesForm">
            <ErrorMessages
              errors={errors}
              humanizeAttributes={humanizeAttributes}
            ></ErrorMessages>
            <Row>
              <Col md="6">
                <Form.Group>
                  <label>{humanizeAttributes["name"]}</label>
                  <Form.Control
                    placeholder="Digite aqui..."
                    type="text"
                    disabled={show}
                    name="name"
                    value={hostessContext.state.name}
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        name: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <label>{humanizeAttributes["nickname"]}</label>
                  <Form.Control
                    placeholder="Digite aqui..."
                    type="text"
                    disabled={show}
                    name="nickname"
                    value={hostessContext.state.nickname}
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        nickname: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Form.Group>
                  <label>{humanizeAttributes["phone"]}</label>
                  {show ? (
                    <Form.Control
                    placeholder="Digite aqui..."
                    type="text"
                    disabled={show}
                    value={hostessContext.state.phone}>
                    
                  </Form.Control>
                  ) : (

                  <PhoneInput
                    country={"br"}
                    value={hostessContext.state.phone}
                    disabled={show}
                    inputStyle={{ width: "100%" }}
                    onChange={(phone) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        phone: phone,
                      })
                    }
                  />)}
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>{humanizeAttributes["email"]}</label>
                  <Form.Control
                    placeholder="Digite aqui..."
                    type="text"
                    disabled={show}
                    value={hostessContext.state.email}
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        email: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>{humanizeAttributes["recommendation"]}</label>
                  <Form.Control
                    placeholder="Digite aqui..."
                    type="text"
                    disabled={show}
                    name="recommendation"
                    value={hostessContext.state.recommendation}
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        recommendation: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col md="3">
              <Form.Group>
                  <label>{humanizeAttributes["status"]}</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="pixType"
                    value={dataStatusType.find(
                      (obj) => obj.value === hostessContext.state.status
                    )}
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        status: e.value,
                      })
                    }
                    options={dataStatusType}
                    placeholder="Selecione o Status"
                  />
                </Form.Group>

              </Col>
              <Col md="3">
                <Form.Group>
                  <label>{humanizeAttributes["recruiter_code"]}</label>
                  <Form.Control
                    placeholder="Digite aqui..."
                    type="text"
                    disabled={show}
                    name="recruiter_code"
                    value={hostessContext.state.recruiter_code}
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        recruiter_code: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <label>{humanizeAttributes["recruiter"]}</label>
                  <Form.Control
                    placeholder="Digite aqui..."
                    type="text"
                    disabled={show}
                    name="recruiter"
                    value={hostessContext.state.recruiter}
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        recruiter: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label>{humanizeAttributes["entry_date"]}</label>
                <Form.Group>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Selecione a Data de Entrada",
                    }}
                    locale="pt-br"
                    value={hostessContext.state.entry_date && new Date(hostessContext.state.entry_date).toLocaleDateString('en-GB')}
                    closeOnSelect="true"
                    dateFormat="DD/MM/YYYY"
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        entry_date: e,
                      })
                    }
                    timeFormat={false}
                  ></ReactDatetime>
                </Form.Group>
              </Col>
              <Col md="3">
                <label>{humanizeAttributes["birth_date"]}</label>
                <Form.Group>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Selecione a Data de Entrada",
                    }}
                    value={hostessContext.state.birth_date && new Date(hostessContext.state.birth_date).toLocaleDateString('en-GB')}
                    locale="pt-br"
                    closeOnSelect="true"
                    dateFormat="DD/MM/YYYY"
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        birth_date: e,
                      })
                    }
                    timeFormat={false}
                  ></ReactDatetime>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <label>{humanizeAttributes["marital_status"]}</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="pixType"
                    value={dataMaritalStatus.find(
                      (obj) => obj.value === hostessContext.state.marital_status
                    )}
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        marital_status: e.value,
                      })
                    }
                    options={dataMaritalStatus}
                    placeholder="Selecione o Tipo de Pix"
                  />
                </Form.Group>
              </Col>
              <Col md="3">
              <Form.Group>
                  <label>{humanizeAttributes["children"]}</label>
                  <Form.Control
                    placeholder="Digite aqui..."
                    type="text"
                    disabled={show}
                    value={hostessContext.state.children}
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        children: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md="3">
                <Form.Group>
                <label>{humanizeAttributes["bank"]}</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="bank"
                    value={dataBank.find(
                      (obj) => obj.value === hostessContext.state.bank
                    )}
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        bank: e.value,
                      })
                    }
                    options={dataBank}
                    placeholder="Selecione o Tipo de Conta"
                  />
                </Form.Group>
              </Col>
              <Col md="3">
              <Form.Group>
                  <label>{humanizeAttributes["account_type"]}</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="pixType"
                    value={dataAccountType.find(
                      (obj) => obj.value === hostessContext.state.account_type
                    )}
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        account_type: e.value,
                      })
                    }
                    options={dataAccountType}
                    placeholder="Selecione o Tipo de Conta"
                  />
                </Form.Group>

              </Col>
              <Col md="3">
                <Form.Group>
                  <label>{humanizeAttributes["bank_agency"]}</label>
                  <Form.Control
                    placeholder="Digite aqui..."
                    type="text"
                    disabled={show}
                    value={hostessContext.state.bank_agency}
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        bank_agency: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group>
                  <label>{humanizeAttributes["account"]}</label>
                  <Form.Control
                    placeholder="Digite aqui..."
                    type="text"
                    disabled={show}
                    value={hostessContext.state.account}
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        account: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Group>
                  <label>{humanizeAttributes["pix_type"]}</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="pixType"
                    value={dataPixType.find(
                      (obj) => obj.value === hostessContext.state.pix_type
                    )}
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        pix_type: e.value,
                      })
                    }
                    options={dataPixType}
                    placeholder="Selecione o Tipo de Pix"
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <label>{humanizeAttributes["pix"]}</label>
                  <Form.Control
                    placeholder="Digite aqui..."
                    type="text"
                    disabled={show}
                    value={hostessContext.state.pix}
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        pix: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <label>{humanizeAttributes["country"]}</label>

                <ReactCountryDropdown
                  onSelect={(e) =>
                    hostessContext.setState({
                      ...hostessContext.state,
                      country: e.code,
                    })
                  }
                  countryCode="BR"
                />
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>{humanizeAttributes["state"]}</label>
                  <Form.Control
                    placeholder="Digite aqui..."
                    type="text"
                    disabled={show}
                    value={hostessContext.state.state}
                    onChange={(e) =>
                      hostessContext.setState({
                        ...hostessContext.state,
                        state: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="4">
                <label>{humanizeAttributes["city"]}</label>
                <Form.Control
                  placeholder="Digite aqui..."
                  type="text"
                  disabled={show}
                  value={hostessContext.state.city}
                  onChange={(e) =>
                    hostessContext.setState({
                      ...hostessContext.state,
                      city: e.target.value,
                    })
                  }
                ></Form.Control>
              </Col>
            </Row>
            <Row>
            <Col md="12">
            <label>{humanizeAttributes["dossier"]}</label>
                <Form.Control
                  placeholder="Digite aqui..."                  
                  as="textarea" 
                  rows={3}
                  disabled={show}
                  value={hostessContext.state.dossier}
                  onChange={(e) =>
                    hostessContext.setState({
                      ...hostessContext.state,
                      dossier: e.target.value,
                    })
                  }
                ></Form.Control>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {!show && (
            <Button
              variant="primary"
              onClick={
                hostessContext.state.id === "" ? handleSubmit : handleUpdate
              }
            >
              {hostessContext.state.id === "" ? "Salvar" : "Atualizar"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default newHostesses;
