import React from "react";
import axios from "axios";
import { API_URL } from "utils/base-url";

export const data = {
  id: "",
  bonus_coins: "",
  penalty: "",
  bonus_money: "",
  weekly_statement_id: '',
  total_paid_money_real: '',
  errors: ""
};

export const humanizeAttributes = {
  bonus_coins: 'Bonus Coins',
  penalty: 'Penalidade %',
  bonus_money: "Bonus $",
  total_paid_money_real: 'Valor Pago',
  weekly_statement_id: ''
}

export const initialState = data;

const DataContext = React.createContext(data);

export default DataContext;
