//client/src/App.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { API_URL } from "utils/base-url";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import Login from "views/Login";
import UserContext, { data, initialState, labels } from "contexts/UserContext";
//import useToken from "utils/useToken";

function App(props) {
  const [state, setState] = useState(data);
  //const { token, setToken } = useToken();

  const loginStatus = () => {
    const params = getCurrentUser() ? { user_id: getCurrentUser().id } : {};
    if (Object.keys(params).length === 0) {
      setState({
        isLoggedIn: false,
        user: {},
      });
    } else {
      axios
        .get(API_URL + "logged_in", { params: params, withCredentials: true })
        .then((response) => {
          if (response.data.logged_in) {
            handleLogin(response.data);
          } else {
            handleLogout();
          }
        })
        .catch((error) => { 
          handleLogout();
          console.log("api errors:", error)
        }
          );
    }
  };

  const handleLogin = (data) => {
    setState({
      isLoggedIn: true,
      user: data.user,
      token: data.token,
      exp: data.exp,
    });
    localStorage.setItem("user", JSON.stringify(data.user));
    if (data.token) {
      localStorage.setItem("token", data.token);
      axios.defaults.headers.get['Authorization'] = localStorage.getItem("token");
      axios.defaults.headers.common['Authorization'] = localStorage.getItem("token");

    }
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    setState({
      isLoggedIn: false,
      user: {},
    });
  };

  const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };

  useEffect(() => {
    loginStatus();
  }, []);

  function PrivateRoute({ component: Component, authed, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          getCurrentUser() ? (
            <>
              <Redirect from="/auth/login-page" to="/admin/dashboard" />
              <Component {...props} />
            </>
          ) : (
            <>
              <Redirect
                to={{
                  pathname: "/auth/login-page",
                  state: { from: props.location },
                }}
              />
            </>
          )
        }
      />
    );
  }

  return (
    <UserContext.Provider value={{ state, setState }}>
      <BrowserRouter>
        <Switch>
          <Route
            path="/auth/login-page"
            render={(props) => <Login handleLogin={handleLogin} {...props} />}
          />
          <PrivateRoute
            authed={state.isLoggedIn}
            path="/admin"
            component={AdminLayout}
          />
          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
