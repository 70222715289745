import React from "react";
import axios from "axios";
import { API_URL } from "utils/base-url";

export const data = {
  id: "",
  start_date: "",
  end_date: "",
  week_name: "",
  errors: "",
  app_id: ""
};

export const humanizeAttributes = {
  start_date: 'Data de Início',
  end_date: 'Data Fim',
  week_name: "Desc Semana",
  dollar_rate: "Cotação Dolar",
  app_id: "APP"
}

export const initialState = data;

export const labels = ["Data de Início", "Data Fim","Cotação Dolar"];

const DataContext = React.createContext(data);

export default DataContext;
