import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { API_URL } from "utils/base-url";
import WeeklyStatementForm from "views/WeeklyStatements/Form";
import WeeklyStatementContext, {
  data,
  initialState,
  labels,
} from "../../contexts/WeeklyStatementContext";
import { confirm } from "components/Forms/DeleteConfirmation";
import ImportDailyForm from "views/WeeklyStatements/ImportDailyForm";
import WeekReport from "./WeekReport";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Table,
} from "react-bootstrap";
import moment from "moment";

export default function WeeklyStatement() {
  const [state, setState] = useState(data);
  const [objects, setObjects] = useState([]);
  const [dailyReports, setDailyReports] = useState({});
  const [dailyReportsTotal, setDailyReportsTotal] = useState([]);
  const [totalReport, setTotalReport] = useState({});
  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(false);
  const indexRoute = "weekly_statements";
  const app_id = 2;

  // *********************** MÉTODOS CRUD ************************* //

  const getObjects = async () => {
    try {
      const { data } = await axios.get(API_URL + "/" + indexRoute, {
        params: {app_id: app_id}
      });
      setObjects(data);
    } catch (e) {
      //dispatch({ type: "SET_ERROR", payload: "Something went wrong." });
      console.error(e);
    }
  };

  function handleEdit(weeklyStatement) {
    setShow(false);
    setState(weeklyStatement);
    setModal(true);
  }

  const handleShow = (weeklyStatement) => {
    setShow(true);
    setState(weeklyStatement);
    setModal(true);
  };

  const handleNew = () => {
    setShow(false);
    handleClear();
    setModal(true);
  };

  function handleSelect(e) {
    setState(e);
    getDailyReports(e.id);
  }

  const getDailyReports = async (weekly_statement_id) => {
    try {
      const { data } = await axios.get(API_URL + "/" + "daily_reports", {
        params: { weekly_statement_id: weekly_statement_id,        
                  app_id: app_id }
      });
      setDailyReports(data.weekly);
      setDailyReportsTotal(data.total);
      setTotalReport(data.total_report);
    } catch (e) {
      //dispatch({ type: "SET_ERROR", payload: "Something went wrong." });
      console.error(e);
    }
  };

  function handleClear() {
    setState(initialState);
  }

  const handleDelete = async (id) => {
    if (await confirm("Deseja realmente excluir o registro?")) {
      const result = { error: false };

      try {
        await axios.delete(`${API_URL}/${indexRoute}/${id}`);
        getObjects();
      } catch (e) {
        result.error = true;
        console.error(e);
      }

      return result;
    }
  };

  // *********************** MÉTODOS CRUD ************************* //

  useEffect(() => {
    getObjects();
  }, []);

  return (
    <>
      <WeeklyStatementContext.Provider value={{ state, setState }}>
        <Container fluid>
          <Row>
            <Col lg="4" sm="12">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="2">
                      <div className="icon-big text-center icon-success">
                        <i className="nc-icon nc-watch-time text-success"></i>
                      </div>
                    </Col>
                    <Col xs="10">
                      <Form.Group>
                        <label>Selecione a Semana</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="appId"
                          getOptionLabel={(option) => option.week_name}
                          getOptionValue={(option) => option.id}
                          value={state}
                          onChange={(e) => handleSelect(e)}
                          options={objects}
                          placeholder="Selecione a Semana"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <div className="stats">
                    <div style={{ textAlign: "right", marginRight: "30px" }}>
                      <Button variant="default" onClick={handleNew}>
                        <i className="nc-icon nc-simple-add icon-bold" />{" "}
                        Iniciar Semana
                      </Button>
                    </div>
                  </div>
                </Card.Footer>
              </Card>

              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Semana</Card.Title>
                </Card.Header>
                <Card.Body className="table-responsive p-0">
                  <Col className="ml-auto" md="3"></Col>
                  <WeeklyStatementForm
                    refresh={getObjects}
                    setModal={setModal}
                    show={show}
                    handleClear={handleClear}
                    modal={modal}
                    app_id={app_id}
                  ></WeeklyStatementForm>
                  <Row>
                    <Col md="12" style={{maxHeight: '300px', overflow: 'auto'}}>
                      <Table className="table-hover table-striped w-full">
                        <thead>
                          <tr>
                            {labels &&
                              labels.map((label, i) => {
                                return <th key={i}>{label}</th>;
                              })}
                            <th className="text-center">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {objects &&
                            objects.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>
                                    {moment(
                                      data.start_date,
                                      "YYYY-MM-DD"
                                    ).format("DD/MM/YYYY")}
                                  </td>
                                  <td>
                                    {moment(data.end_date, "YYYY-MM-DD").format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>
                                   {data.dollar_rate && Number(data.dollar_rate).toLocaleString("pt-BR", {style: "currency",currency: "BRL",})}
                                  </td>
                                  <td className="td-actions text-center">
                                    <OverlayTrigger
                                      href="#"
                                      onClick={(e) => handleShow(data)}
                                      overlay={
                                        <Tooltip id="tooltip-48903503">
                                          View Profile..
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        className="btn-link btn-xs"
                                        href="#"
                                        onClick={(e) => handleShow(data)}
                                        variant="info"
                                      >
                                        <i className="fas fa-weeklyStatement"></i>
                                      </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      href="#"
                                      onClick={(e) => handleEdit(data)}
                                      overlay={
                                        <Tooltip id="tooltip-981231696">
                                          Editar
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        className="btn-link btn-xs"
                                        href="#"
                                        onClick={(e) => handleEdit(data)}
                                        variant="success"
                                      >
                                        <i className="fas fa-edit"></i>
                                      </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      href="#"
                                      onClick={(e) => handleDelete(data.id)}
                                      overlay={
                                        <Tooltip id="tooltip-255158527">
                                          Remover
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        className="btn-link btn-xs"
                                        href="#"
                                        onClick={() => handleDelete(data.id)}
                                        variant="danger"
                                      >
                                        <i className="fas fa-times"></i>
                                      </Button>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="8" sm="12">
              <ImportDailyForm
                getDailyReports={getDailyReports}
                app_id={app_id}
              ></ImportDailyForm>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <WeekReport
                dailyReports={dailyReports}
                dailyReportsTotal={dailyReportsTotal}
                totalReport={totalReport}
                getDailyReports={getDailyReports}
              ></WeekReport>
            </Col>
          </Row>
        </Container>
      </WeeklyStatementContext.Provider>
    </>
  );
}
