import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { API_URL } from "utils/base-url";
import HostessForm from "views/Hostesses/Form";
import HostessContext, {
  data,
  initialState,
  labels,
} from "../../contexts/HostessContext";
import { confirm } from "components/Forms/DeleteConfirmation";
import ReactTable from "components/ReactTable/ReactTable.js";
import HostessAgencyTable from "views/HostessAgencies/IndexTable";
import ImportHostesses from "./ImportHostesses";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Table,
} from "react-bootstrap";

export default function Hostess() {
  const [objects, setObjects] = useState([]);
  const [state, setState] = useState(data);
  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(false);
  const indexRoute = "hostesses";
  const title = "Hostess";

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRowAsync
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
      />
    ),
    []
  );

  function SubRowAsync({ row, rowProps, visibleColumns }) {
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }, []);

    return (
      <SubRows
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        data={row.original}
        loading={loading}
      />
    );
  }

  // This could be inlined into SubRowAsync, this this lets you reuse it across tables
  function SubRows({ row, rowProps, visibleColumns, data, loading }) {
    if (loading) {
      return (
        <tr>
          <td />
          <td colSpan={visibleColumns.length - 1}>Carregando...</td>
        </tr>
      );
    }
    // error handling here :)

    return (
      <>
      <HostessAgencyTable hostess={data}>

      </HostessAgencyTable>
      </>
    );
  }

  // *********************** MÉTODOS CRUD ************************* //

  const getObjects = async () => {
    try {
      const { data } = await axios.get(API_URL + "/" + indexRoute);
      setObjects(
        data.map((obj) => ({
          ...obj,
          entry_date: new Date(obj["entry_date"]).toLocaleDateString("en-GB"),
          actions: (
            <div className="actions-right">
              <OverlayTrigger
                href="#"
                onClick={(e) => handleShow(obj)}
                overlay={
                  <Tooltip id="tooltip-48903503">View Profile..</Tooltip>
                }
              >
                <Button
                  size="sm"
                  className="text-info btn-link like"
                  href="#"
                  onClick={(e) => handleShow(obj)}
                  variant="info"
                >
                  <i className="fas fa-user"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                href="#"
                onClick={(e) => handleEdit(obj)}
                overlay={<Tooltip id="tooltip-981231696">Editar</Tooltip>}
              >
                <Button
                  variant="warning"
                  size="sm"
                  className="text-warning btn-link edit"
                  href="#"
                  onClick={(e) => handleEdit(obj)}
                >
                  <i className="fas fa-edit"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                href="#"
                onClick={(e) => handleDelete(obj.id)}
                overlay={<Tooltip id="tooltip-255158527">Remover</Tooltip>}
              >
                <Button
                  variant="danger"
                  size="sm"
                  className="btn-link remove text-danger"
                  href="#"
                  onClick={() => handleDelete(obj.id)}
                >
                  <i className="fas fa-times"></i>
                </Button>
              </OverlayTrigger>
            </div>
          ),
        }))
      );
    } catch (e) {
      //dispatch({ type: "SET_ERROR", payload: "Something went wrong." });
      console.error(e);
    }
  };

  function handleEdit(app) {
    setShow(false);
    setState(app);
    setModal(true);
  }

  const handleShow = (app) => {
    setShow(true);
    setState(app);
    setModal(true);
  };

  const handleNew = () => {
    setShow(false);
    handleClear();
    setModal(true);
  };

  function handleClear() {
    setState(initialState);
  }

  const handleDelete = async (id) => {
    if (await confirm("Deseja realmente excluir o registro?")) {
      const result = { error: false };

      try {
        await axios.delete(`${API_URL}/${indexRoute}/${id}`);
        getObjects();
      } catch (e) {
        result.error = true;
        console.error(e);
      }

      return result;
    }
  };

  // *********************** MÉTODOS CRUD ************************* //

  useEffect(() => {
    getObjects();
  }, []);

  return (
    <>
      <HostessContext.Provider value={{ state, setState }}>
        
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">{title}</Card.Title>
                </Card.Header>
                <Card.Body className="table-responsive p-0">
                  <Col className="ml-auto" md="3">
                    <div style={{ textAlign: "right", marginRight: "30px" }}>
                      <Button variant="default" onClick={handleNew}>
                        <i className="nc-icon nc-simple-add icon-bold" /> Nova
                        Hostess
                      </Button>
                    </div>
                  </Col>
                  <HostessForm
                    refresh={getObjects}
                    setModal={setModal}
                    show={show}
                    handleClear={handleClear}
                    modal={modal}
                  ></HostessForm>
                  <Row>
                    <Col md="12">
                      <ReactTable
                        data={objects}
                        columns={labels}
                        renderRowSubComponent={renderRowSubComponent}
                        /*
                    You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                  */
                        className="-striped -highlight success-pagination"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <ImportHostesses refresh={getObjects} />

            </Col>
          </Row>
        </Container>
      </HostessContext.Provider>
    </>
  );
}
