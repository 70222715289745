import React from 'react'

export const data = {
    id: '',
    agency: '',
    hostess: '',
    code: '',
    entry_date: '',
    agency_id: '',
    hostess_id: '',
  }

export const initialState = data;

export const labels = ['Agência', 'Código','Data de Entrada']

const DataContext = React.createContext(data)

export const humanizeAttributes = {
  agency: 'Agência',
  hostess: 'Hostess',
  agency_id: 'Agência',
  hostess_id: 'Hostess',
  code: 'Código',
  entry_date: 'Data de Entrada'
}

export default DataContext;