import React from 'react'

export const data = {
    id: '',
    app_id: '',
    name: '',
    app_id: '',
    email: '',
    contact_name: '',
    contact_phone: '',
  }

export const initialState = data;

export const labels = ['Aplicativo','Agência', 'Código','Responsável', 'Fone', 'Email']

const DataContext = React.createContext(data)

export const humanizeAttributes = {
  name: 'Agência',
  email: 'Email',
  code: 'Código',
  contact_name: 'Responsável',
  contact_phone: 'Fone',
  app_id: 'Aplicativo',
  app: 'Aplicativo'
}

export default DataContext;