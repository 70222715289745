import React, { useContext, useState } from "react";
import axios from "axios";
import { Button, Form, InputGroup, Row, Col, Modal } from "react-bootstrap";
import Select from "react-select";
import { API_URL } from "utils/base-url";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import UserContext, { humanizeAttributes } from "../../contexts/UserContext";
import ErrorMessages from "components/Forms/ErrorMessages";

function newUser(props) {
  const userContext = useContext(UserContext);
  const [errors, setErrors] = useState(null);
  const refresh = props.refresh;
  const setModal = props.setModal;
  const handleClear = props.handleClear;
  const show = props.show;

  const handleClose = () => {
    setErrors(null);
    setModal(false);
  };
  const handleSubmit = () => {
    setErrors(null);
    axios
      .post(API_URL + "users", {
        user: userContext.state,
      })
      .then((resp) => handleResult())
      .catch((error) => {
        setErrors(error.response.data);
        return error;
      });
  };

  const handleUpdate = () => {
    setErrors(null);
    axios
      .put(API_URL + "users/" + userContext.state.id, {
        user: userContext.state,
      })
      .then((resp) => handleResult())
      .catch((error) => {
        setErrors(error.response.data);
        return error;
      });
  };

  function handleResult() {
    setErrors(null);
    //refresh();
    handleClear();
    setModal(false);
  }

  return (
    <>
      <Row></Row>
      <Modal show={props.modal} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Novo Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="UserForm">
            <ErrorMessages
              errors={errors}
              humanizeAttributes={humanizeAttributes}
            ></ErrorMessages>
            <Form.Group>
              <label>Usuário</label>
              <Form.Control
                placeholder="Digite o Usuário"
                type="username"
                value={userContext.state.username}
                onChange={(e) =>
                  userContext.setState({
                    ...userContext.state,
                    username: e.target.value,
                  })
                }
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <label>Email</label>
              <Form.Control
                placeholder="Digite o email"
                type="email"
                value={userContext.state.email}
                onChange={(e) =>
                  userContext.setState({
                    ...userContext.state,
                    email: e.target.value,
                  })
                }
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <label>Senha</label>
              <Form.Control
                placeholder="Digite a Senha"
                type="password"
                onChange={(e) =>
                  userContext.setState({
                    ...userContext.state,
                    password: e.target.value,
                  })
                }
              ></Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {!show && (
            <Button
              variant="primary"
              onClick={
                userContext.state.id === "" ? handleSubmit : handleUpdate
              }
            >
              {userContext.state.id === "" ? "Salvar" : "Atualizar"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default newUser;
