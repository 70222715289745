import React, { useContext, useState } from "react";
import axios from "axios";
import { Button, Form, InputGroup, Row, Col, Modal } from "react-bootstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { API_URL } from "utils/base-url";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import WeeklyStatementContext, { humanizeAttributes } from "../../contexts/WeeklyStatementContext";
import ErrorMessages from "components/Forms/ErrorMessages";

function newWeeklyStatement(props) {
  const weeklyStatementContext = useContext(WeeklyStatementContext);
  const [errors, setErrors] = useState(null);
  const refresh = props.refresh;
  const setModal = props.setModal;
  const handleClear = props.handleClear;
  const show = props.show;
  const app_id = props.app_id;
  

  const handleClose = () => {
    setErrors(null);
    setModal(false);
  };
  const handleSubmit = () => {
    setErrors(null);        
    weeklyStatementContext.state.app_id = app_id;    
    axios
      .post(API_URL + "weekly_statements", {
        weekly_statement: weeklyStatementContext.state,
      })
      .then((resp) => handleResult())
      .catch((error) => {
        setErrors(error.response.data);
        return error;
      });
  };

  const handleUpdate = () => {
    setErrors(null);
    weeklyStatementContext.state.app_id = app_id;    
  debugger
    axios
      .put(API_URL + "weekly_statements/" + weeklyStatementContext.state.id, {
        weekly_statement: weeklyStatementContext.state,
      })
      .then((resp) => handleResult())
      .catch((error) => {
        setErrors(error.response.data);
        return error;
      });
  };

  function handleResult() {
    setErrors(null);
    refresh();
    handleClear();
    setModal(false);
  }

  return (
    <>
      <Row></Row>
      <Modal show={props.modal} onHide={handleClose} backdrop="static" size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Iniciar Semana</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="WeeklyStatementForm">
            <ErrorMessages
              errors={errors}
              humanizeAttributes={humanizeAttributes}
            ></ErrorMessages>
             <Row>
              <Col md="4">
                <label>{humanizeAttributes["start_date"]}</label>
                <Form.Group>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Selecione a Data Inicial",
                    }}
                    locale="pt-br"
                    value={weeklyStatementContext.state.start_date && new Date(weeklyStatementContext.state.start_date).toLocaleDateString('en-GB')}
                    closeOnSelect="true"
                    dateFormat="DD/MM/YYYY"
                    onChange={(e) =>
                      weeklyStatementContext.setState({
                        ...weeklyStatementContext.state,
                        start_date: e,
                      })
                    }
                    timeFormat={false}
                  ></ReactDatetime>
                </Form.Group>
              </Col>
              <Col md="4">
                <label>{humanizeAttributes["end_date"]}</label>
                <Form.Group>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Selecione a Data Final",
                    }}
                    locale="pt-br"
                    value={weeklyStatementContext.state.end_date && new Date(weeklyStatementContext.state.end_date).toLocaleDateString('en-GB')}
                    closeOnSelect="true"
                    dateFormat="DD/MM/YYYY"
                    onChange={(e) =>
                      weeklyStatementContext.setState({
                        ...weeklyStatementContext.state,
                        end_date: e,
                      })
                    }
                    timeFormat={false}
                  ></ReactDatetime>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>{humanizeAttributes["dollar_rate"]}</label>
                  <Form.Control
                    placeholder="Digite aqui..."
                    type="number"
                    disabled={show}
                    value={weeklyStatementContext.state.dollar_rate}
                    onChange={(e) =>
                      weeklyStatementContext.setState({
                        ...weeklyStatementContext.state,
                        dollar_rate: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
              </Row>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {!show && (
            <Button
              variant="primary"
              onClick={
                weeklyStatementContext.state.id === "" ? handleSubmit : handleUpdate
              }
            >
              {weeklyStatementContext.state.id === "" ? "Salvar" : "Atualizar"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default newWeeklyStatement;
