import React, { useContext, useState } from "react";
import axios from "axios";
import { Button, Form, InputGroup, Row, Col, Modal } from "react-bootstrap";
import Select from "react-select";
import { API_URL } from "utils/base-url";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AppContext, { dataOs, humanizeAttributes }  from "../../contexts/AppContext";
import ErrorMessages from "components/Forms/ErrorMessages";

function newApp(props) {
  const appContext = useContext(AppContext);
  const [errors, setErrors] = useState(null);  
  const refresh = props.refresh;
  const setModal = props.setModal;
  const handleClear = props.handleClear;
  const show = props.show;

  const handleClose = () => {
    setErrors(null);
    setModal(false);
  }
  const handleSubmit = () => {
    setErrors(null);
    axios
      .post(API_URL + "apps", {
        app: appContext.state,
      })
      .then((resp) => handleResult())
      .catch(error => {        
        setErrors(error.response.data)        
        return error;
      });
  };

  const handleUpdate = () => {
    setErrors(null);
    axios
      .put(API_URL + "apps/" + appContext.state.id, {
        app: appContext.state,
      })
      .then((resp) => handleResult())
      .catch(error => {        
        setErrors(error.response.data)        
        return error;
      });
  };  

  function handleResult() {    
    setErrors(null);
    refresh();
    handleClear();
    setModal(false);
  }

  return (
    <>
      <Row></Row>
      <Modal show={props.modal} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Novo Aplicativo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="AppForm">
            <ErrorMessages errors={errors} humanizeAttributes={humanizeAttributes}></ErrorMessages>
            <Form.Group>
              <label>Aplicativo</label>
              <Form.Control
                placeholder="Digite aqui..."
                type="text"
                disabled={show}     
                name='name'                     
                value={appContext.state.name}
                onChange={(e) =>
                  appContext.setState({
                    ...appContext.state,
                    name: e.target.value,
                  })
                }
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <label>S.O</label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"         
                name="operationalSystem"
                value={dataOs.find(
                  (obj) => obj.value === appContext.state.operational_system
                )}
                onChange={(e) =>
                  appContext.setState({
                    ...appContext.state,
                    operational_system: e.value,
                  })
                }
                options={dataOs}
                placeholder="Selecione o Sistema Operacional"
              />
            </Form.Group>
            <Form.Group>
              <label>Responsável</label>
              <Form.Control
                placeholder="Digite aqui..."
                type="text"
                disabled={show}            
                value={appContext.state.contact_name}
                onChange={(e) =>
                  appContext.setState({
                    ...appContext.state,
                    contact_name: e.target.value,
                  })
                }
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <label>Fone</label>
              <PhoneInput
                country={"br"}
                value={appContext.state.contact_phone}
                disabled={show}            
                inputStyle={{ width: "100%" }}
                onChange={(phone) =>
                  appContext.setState({
                    ...appContext.state,
                    contact_phone: phone,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {!show && (
            <Button
              variant="primary"
              onClick={appContext.state.id === "" ? handleSubmit : handleUpdate}
            >
              { appContext.state.id === "" ? 'Salvar' : 'Atualizar' }
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default newApp;
