import React, { useContext, useState } from "react";
import {
  Card,
  Tab,
  Nav,
  Table,  
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import WeekReportContext, {
  data,
  initialState,
} from "../../contexts/WeekReportContext";
import WeekReportForm from "views/WeeklyStatementsLivu/WeekReportForm";
import WeekReportPaymentForm from "views/WeeklyStatementsLivu/WeekReportPaymentForm";
import axios from "axios";
import { API_URL } from "utils/base-url";
import HostessContext, { dataHostess } from "../../contexts/HostessContext";
import moment from "moment";
function weekReport(props) {
  const dailyReports = props.dailyReports;
  const dailyReportsTotal = props.dailyReportsTotal;
  const getDailyReports = props.getDailyReports;
  const totalReport = props.totalReport;
  const [state, setState] = useState(data);
  const [modal, setModal] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [show, setShow] = useState(false);
  const [hostessState, setHostessState] = useState(dataHostess);

  function handleEdit(report) {
    setShow(false);
    setState(report);
    setModal(true);
  }

  function handleEditPayment(report) {
    setShow(false);
    setState(report);
    setModalPayment(true);
    getHostess(report.hostess_id);
  }

  const getHostess = async (id) => {
    try {
      const { data } = await axios.get(API_URL + "/" + "hostesses/" + id);
      setHostessState(data);
    } catch (e) {
      //dispatch({ type: "SET_ERROR", payload: "Something went wrong." });
      console.error(e);
    }
  };

  function handleClear() {
    setState(initialState);
  }

  function refresh(weekly_statement_id) {
    getDailyReports(weekly_statement_id);
  }

  return (
    <>
      <WeekReportContext.Provider value={{ state, setState }}>
        <WeekReportForm
          refresh={refresh}
          setModal={setModal}
          show={show}
          handleClear={handleClear}
          modal={modal}
        ></WeekReportForm>
        <WeekReportPaymentForm
          refresh={refresh}
          setModal={setModalPayment}
          hostess={hostessState}
          show={show}
          handleClear={handleClear}
          modal={modalPayment}
        ></WeekReportPaymentForm>
        <Card className="card-stats">
          <Card.Header>
            <Card.Title as="h4">Relatório Semanal</Card.Title>
          </Card.Header>
          <Card.Body>
            <Tab.Container id="plain-tabs-example" defaultActiveKey={0}>
              <Nav role="tablist" variant="tabs">
                {Object.keys(dailyReports).length > 0 &&
                  Object.keys(dailyReports).map((data, index) => {
                    return (
                      <Nav.Item>
                        <Nav.Link eventKey={index}>
                          {moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}

                {dailyReportsTotal.length > 0 && (
                  <Nav.Item>
                    <Nav.Link eventKey="total">TOTAL</Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
              <Tab.Content>
                {Object.keys(dailyReports).length > 0 &&
                  Object.keys(dailyReports).map((data, index) => {
                    return (
                      <Tab.Pane eventKey={index} style={{overflow: 'auto'}}>
                        <Table className="table-hover table-striped w-full">
                          <thead>
                            <tr>
                              <th>Código (code)</th>
                              <th>Nome (user_name)</th>
                              <th>Categoria (category)</th>
                              <th>Total Coins (total_coins)</th>
                              <th>Friend Coins (friend_video_coins)</th>
                              <th>Outras (non_friend_video_coins) </th>
                              <th>Presentes (gift_coins) </th>
                              <th>Task Coins (task_coins)</th>
                              <th>Matchs (match_count)</th>
                              <th>Stories (is_have_story)</th>
                              <th>Video Status (video_status)</th>
                              <th>Duração de Chamada (long_call_ratio)</th>
                              <th>KYC PASS (kyc_pass)</th>
                              <th>Banco (bank_country_ab)</th>
                              <th>GROUP TIME (group_time)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dailyReports[data].map((daily) => {
                              return (
                                <tr key={daily.code}>
                                  <td>{daily.code}</td>
                                  <td>{daily.user_name}</td>
                                  <td>{daily.category}</td>
                                  <td>{daily.total_coins}</td>
                                  <td>{daily.friend_video_coins}</td>
                                  <td>{daily.non_friend_video_coins}</td>
                                  <td>{daily.gift_coins}</td>
                                  <td>{daily.task_coins}</td>
                                  <td>{daily.match_count}</td>
                                  <td>{daily.is_have_story}</td>
                                  <td>{daily.video_status}</td>
                                  <td>{daily.long_call_ratio}</td>
                                  <td>{daily.kyc_pass}</td>
                                  <td>{daily.bank_country_ab}</td>
                                  <td>{new Date(daily.group_time).toLocaleDateString("en-GB")}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tab.Pane>
                    );
                  })}
                {dailyReportsTotal.length > 0 && (
                  <Tab.Pane eventKey="total">
                    <Row>
                    <Col lg="3" sm="6">
                        <Card className="card-stats">
                          <Card.Body>
                            <Row>
                              <Col xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <i className="nc-icon nc-money-coins text-warning"></i>
                                </div>
                              </Col>
                              <Col xs="7">
                                <div className="numbers">
                                  <p className="card-category">TOTAL HOSTESS</p>
                                  <Card.Title as="h4">{ Number(totalReport.total_hostess).toLocaleString(
                                  "en-US",
                                  { style: "currency", currency: "USD" }) }</Card.Title>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col lg="3" sm="6">
                        <Card className="card-stats">
                          <Card.Body>
                            <Row>
                              <Col xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <i className="nc-icon nc-money-coins text-warning"></i>
                                </div>
                              </Col>
                              <Col xs="7">
                                <div className="numbers">
                                  <p className="card-category">Comissão $</p>
                                  <Card.Title as="h4">{ Number(totalReport.total_commission).toLocaleString(
                                  "en-US",
                                  { style: "currency", currency: "USD" }) }</Card.Title>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                      
                   
                      <Col lg="3" sm="6">
                        <Card className="card-stats">
                          <Card.Body>
                            <Row>
                              <Col xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <i className="nc-icon nc-money-coins text-warning"></i>
                                </div>
                              </Col>
                              <Col xs="7">
                                <div className="numbers">
                                  <p className="card-category">Meta</p>
                                  <Card.Title as="h4">{ totalReport.goal }</Card.Title>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg="3" sm="6">
                        <Card className="card-stats">
                          <Card.Body>
                            <Row>
                              <Col xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <i className="nc-icon nc-money-coins text-warning"></i>
                                </div>
                              </Col>
                              <Col xs="7">
                                <div className="numbers">
                                  <p className="card-category">Acumulado</p>
                                  <Card.Title as="h4">{ totalReport.accumulated_goal }</Card.Title>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>

                    <Table className="table-hover table-striped w-full">
                      <thead>
                        <tr>
                          <th>Código (anchor_id)</th>
                          <th>Nome (anchor_name)</th>
                          <th>Grupo (group)</th>
                          {/*<th>Agência</th>*/}
                          <th>Semana Coins</th>
                          <th>Ultima Semana 1</th>
                          <th>Ultima Semana 2</th>
                          <th>Ultima Semana 3</th>
                          <th>Bonus Coins</th>
                          <th>Total Coins</th>
                          <th>Bonus $</th>
                          <th>Penalidade %</th>
                          <th>Total $</th>
                          <th>Total Real</th>
                          <th>Comissão %</th>
                          <th>Comissão $</th>
                          <th>Pago</th>
                                  <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dailyReportsTotal.map((report) => {
                          return (
                            <tr key={report.id}>
                              <td>{report.code}</td>
                              <td>{report.anchor_name}</td>
                              <td>{report.group}</td>
                              <td>
                                {Number(report.week_coins).toLocaleString(
                                  "pt-BR"
                                )}
                              </td>
                              <td>
                                {report.last_week_coins_1.toLocaleString("pt-BR")}
                              </td>
                              <td>
                                {report.last_week_coins_2.toLocaleString("pt-BR")}
                              </td>
                              <td>
                                {report.last_week_coins_3.toLocaleString("pt-BR")}
                              </td>
                              <td>
                                {report.bonus_coins.toLocaleString("pt-BR")}
                              </td>
                              <td>
                                {report.total_coins.toLocaleString("pt-BR")}
                              </td>
                              
                              <td>
                                {Number(report.bonus_money).toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )}
                              </td>
                              <td>{report.penalty}</td>
                              <td>
                                {Number(report.total_money).toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )}
                              </td>
                              <td>
                                {Number(report.total_money_real).toLocaleString(
                                  "pt-BR",
                                  {
                                    style: "currency",
                                    currency: "BRL",
                                  }
                                )}
                              </td>
                              <td>{report.commission_tax} %</td>
                              <td>
                                {Number(report.total_commission).toLocaleString(
                                  "en-US",
                                  { style: "currency", currency: "USD" }
                                )}
                              </td>
                              <td>
                                {report.total_paid_money_real && (
                                  <button
                                    type="button"
                                    class="btn-wd mr-1 btn btn-success"
                                    style={{ minWidth: "70px" }}
                                  >
                                    {Number(
                                      report.total_paid_money_real
                                    ).toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })}
                                  </button>
                                )}
                              </td>

                              <td>
                                <>
                                <button
                                  type="button"
                                  class="btn-wd btn-outline mr-1 btn btn-warning"
                                  onClick={(e) => handleEdit(report)}
                                >
                                  <span class="btn-label">
                                    <i class="fas fa-edit"></i>
                                  </span>{" "}
                                  Alterar
                                </button>

                                <button
                                  type="button"
                                  class="btn-wd btn-outline mr-1 btn btn-info"
                                  onClick={(e) => handleEditPayment(report)}
                                >
                                  <span class="btn-label">
                                    <i class="fas fa-money-bill"></i>
                                  </span>{" "}
                                  Pagar
                                </button>
                                </> 
                              </td>
                                
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                )}
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </WeekReportContext.Provider>
    </>
  );
}

export default weekReport;
