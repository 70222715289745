import React from "react";
import axios from "axios";
import { API_URL } from "utils/base-url";

export const data = {
  id: '',
  name: '',
  nickname: '',
  phone: '',
  email: '',
  pix: '',
  entry_date: '',
  pix_type: '',
  marital_status: '',
  birth_date: '',
  bank: '',
  account_type: '',
  account: '',
  bank_agency: '',
  country: '',
  state: '',
  city: '',
  more_info: '',
  children: '',
  dossier: '',
  recommendation: '',
  recruiter_code: '',
  recruiter: '',
  status: ''
};

export const dataHostess = data;

export const initialState = data;

export const humanizeAttributes = {
  name: 'Nome',
  nickname: 'Apelido',
  phone: 'Telefone',
  email: 'Email',
  pix: 'Chave Pix',
  entry_date: 'Dt. Entrada',
  pix_type: 'Tipo Pix',
  marital_status: 'Estado Civil',
  birth_date: 'Dt. Aniversário',
  bank: 'Banco',
  account_type: 'Tipo de Conta',
  account: 'Conta',
  bank_agency: 'Agência',
  country: 'País',
  state: 'Estado',
  city: 'Cidade',
  more_info: 'Info',
  children: 'Filhos',
  agencies_name: 'Agencias',
  dossier: 'Dossiê',
  recommendation: 'Indicação',
  recruiter_code: 'Cod. Recrutador',
  recruiter: 'Recrutador',
  status: 'Status'
}

export const labels = [
  {
    // Make an expander cell
    Header: () => null, // No header
    id: 'expander', // It needs an ID
    Cell: ({ row }) => (
      // Use Cell to render an expander for each row.
      // We can use the getToggleRowExpandedProps prop-getter
      // to build the expander.
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? '➖' : '➕'}
      </span>
    ),
  },
  {
    Header: humanizeAttributes["agencies_name"],
    accessor: "agencies_name",
    sortable: false,
    filterable: false
  },
  {
  Header: humanizeAttributes["name"],
  accessor: "name",
},
{
  Header: humanizeAttributes["nickname"],
  accessor: "nickname",
},
{
  Header: humanizeAttributes["phone"],
  accessor: "phone",
},
{
  Header: humanizeAttributes["email"],
  accessor: "email",
},
{
  Header: humanizeAttributes["entry_date"],
  accessor: "entry_date",
},
{
  Header: humanizeAttributes["pix"],
  accessor: "pix",
},
{
  Header:  () => (
    <div
      style={{
        textAlign:"center"
      }}
    >Actions</div>),
  accessor: "actions",
  sortable: false,
  filterable: false,
}]

export const dataAccountType = [
  {
    value: "",
    label: humanizeAttributes['account_type'],
    isDisabled: true,
  },
  { value: "corrente", label: "Corrente" },
  { value: "poupanca", label: "Poupança" }
];


export const dataStatusType = [
  {
    value: "",
    label: humanizeAttributes['status'],
    isDisabled: true,
  },
  { value: 'ativo', label: "Ativo" },
  { value: 'inativo', label: "Inativo" }
];

export const dataPixType = [
  {
    value: "",
    label: humanizeAttributes['pix_type'],
    isDisabled: true,
  },
  { value: "cpf", label: "CPF" },
  { value: "email", label: "Email" },
  { value: "phone", label: "Telefone" },
];

export const dataMaritalStatus = [
  {
    value: "",
    label: humanizeAttributes['marital_status'],
    isDisabled: true,
  },
  { value: "solteira", label: "Solteira" },
  { value: "casada", label: "Casada" },
  { value: "divorciada", label: "Divorciada" },
  { value: "viuva", label: "Viúva" },
];

export const dataBank = [
  { value: '001', label: '001 - BANCO DO BRASIL S.A (BB)  ' },
  { value: '237', label: '237 - BRADESCO S.A  ' },
  { value: '335', label: '335 - BANCO DIGIO S.A ' },
  { value: '260', label: '260 - NU PAGAMENTOS S.A (NUBANK)  ' },
  { value: '290', label: '290 - PAGSEGURO INTERNET S.A (PAGBANK ' },
  { value: '380', label: '380 - PICPAY SERVIÇOS S.A.  ' },
  { value: '323', label: '323 - MERCADO PAGO (CARTEIRA DIGITAL) ' },
  { value: '237', label: '237 - NEXT BANK (FINTECH DO  BANCO BRADESCO)  ' },
  { value: '637', label: '637 - BANCO SOFISA S.A (SOFISA DIRETO)  ' },
  { value: '077', label: '077 - BANCO INTER S.A ' },
  { value: '341', label: '341 - ITAÚ UNIBANCO S.A (OS MESMOS DADOS PARA A CONTA ITI)  ' },
  { value: '104', label: '104 - CAIXA ECONÔMICA FEDERAL (CEF) ' },
  { value: '033', label: '033 - BANCO SANTANDER BRASIL S.A  ' },
  { value: '212', label: '212 - BANCO ORIGINAL S.A  ' },
  { value: '756', label: '756 - BANCOOB (BANCO COOPERATIVO DO BRASIL) ' },
  { value: '413', label: '413 - BANCO VOTORANTIM S.A  ' },
  { value: '655', label: '655 - NEON PAGAMENTOS S.A (FINTECH DO BANCO VOTORANTIM) ' },
  { value: '041', label: '041 - BANRISUL – BANCO DO ESTADO DO RIO GRANDE DO SUL S.A ' },
  { value: '389', label: '389 - BANCO MERCANTIL DO BRASIL S.A ' },
  { value: '422', label: '422 - BANCO SAFRA S.A ' },
  { value: '070', label: '070 - BANCO DE BRASÍLIA (BRB) ' },
  { value: '136', label: '136 - UNICRED COOPERATIVA ' },
  { value: '741', label: '741 - BANCO RIBEIRÃO PRETO  ' },
  { value: '739', label: '739 - BANCO CETELEM S.A ' },
  { value: '743', label: '743 - BANCO SEMEAR S.A  ' },
  { value: '100', label: '100 - PLANNER CORRETORA DE VALORES S.A  ' },
  { value: '096', label: '096 - BANCO B3 S.A  ' },
  { value: '747', label: '747 - Banco RABOBANK INTERNACIONAL DO BRASIL S.A  ' },
  { value: '748', label: '748 - SICREDI S.A ' },
  { value: '752', label: '752 - BNP PARIBAS BRASIL S.A  ' },
  { value: '091', label: '091 - UNICRED CENTRAL RS  ' },
  { value: '399', label: '399 - KIRTON BANK ' },
  { value: '108', label: '108 - PORTOCRED S.A ' },
  { value: '757', label: '757 - BANCO KEB HANA DO BRASIL S.A  ' },
  { value: '102', label: '102 - XP INVESTIMENTOS S.A  ' },
  { value: '084', label: '084 - UNIPRIME NORTE DO PARANÁ  ' },
  { value: '180', label: '180 - CM CAPITAL MARKETS CCTVM LTDA ' },
  { value: '066', label: '066 - BANCO MORGAN STANLEY S.A  ' },
  { value: '015', label: '015 - UBS BRASIL CCTVM S.A  ' },
  { value: '143', label: '143 - TREVISO CC S.A  ' },
  { value: '062', label: '062 - HIPERCARD BM S.A  ' },
  { value: '074', label: '074 - BCO. J.SAFRA S.A  ' },
  { value: '099', label: '099 - UNIPRIME CENTRAL CCC LTDA ' },
  { value: '025', label: '025 - BANCO ALFA S.A. ' },
  { value: '075', label: '075 - BCO ABN AMRO S.A  ' },
  { value: '040', label: '040 - BANCO CARGILL S.A ' },
  { value: '190', label: '190 - SERVICOOP ' },
  { value: '063', label: '063 - BANCO BRADESCARD  ' },
  { value: '191', label: '191 - NOVA FUTURA CTVM LTDA ' },
  { value: '064', label: '064 - GOLDMAN SACHS DO BRASIL BM S.A  ' },
  { value: '097', label: '097 - CCC NOROESTE BRASILEIRO LTDA  ' },
  { value: '016', label: '016 - CCM DESP TRÂNS SC E RS  ' },
  { value: '012', label: '012 - BANCO INBURSA ' },
  { value: '003', label: '003 - BANCO DA AMAZONIA S.A ' },
  { value: '060', label: '060 - CONFIDENCE CC S.A ' },
  { value: '037', label: '037 - BANCO DO ESTADO DO PARÁ S.A ' },
  { value: '159', label: '159 - CASA CREDITO S.A  ' },
  { value: '172', label: '172 - ALBATROSS CCV S.A ' },
  { value: '085', label: '085 - COOP CENTRAL AILOS  ' },
  { value: '114', label: '114 - CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO' },
  { value: '036', label: '036 - BANCO BBI S.A ' },
  { value: '394', label: '394 - BANCO BRADESCO FINANCIAMENTOS S.A ' },
  { value: '004', label: '004 - BANCO DO NORDESTE DO BRASIL S.A.  ' },
  { value: '320', label: '320 - BANCO CCB BRASIL S.A  ' },
  { value: '189', label: '189 - HS FINANCEIRA ' },
  { value: '105', label: '105 - LECCA CFI S.A ' },
  { value: '076', label: '076 - BANCO KDB BRASIL S.A. ' },
  { value: '082', label: '082 - BANCO TOPÁZIO S.A ' },
  { value: '286', label: '286 - CCR DE OURO ' },
  { value: '093', label: '093 - PÓLOCRED SCMEPP LTDA  ' },
  { value: '273', label: '273 - CCR DE SÃO MIGUEL DO OESTE  ' },
  { value: '157', label: '157 - ICAP DO BRASIL CTVM LTDA  ' },
  { value: '183', label: '183 - SOCRED S.A  ' },
  { value: '014', label: '014 - NATIXIS BRASIL S.A  ' },
  { value: '130', label: '130 - CARUANA SCFI  ' },
  { value: '127', label: '127 - CODEPE CVC S.A  ' },
  { value: '079', label: '079 - BANCO ORIGINAL DO AGRONEGÓCIO S.A ' },
  { value: '081', label: '081 - BBN BANCO BRASILEIRO DE NEGOCIOS S.A  ' },
  { value: '118', label: '118 - STANDARD CHARTERED BI S.A ' },
  { value: '133', label: '133 - CRESOL CONFEDERAÇÃO ' },
  { value: '121', label: '121 - BANCO AGIBANK S.A ' },
  { value: '083', label: '083 - BANCO DA CHINA BRASIL S.A ' },
  { value: '138', label: '138 - GET MONEY CC LTDA ' },
  { value: '024', label: '024 - BCO BANDEPE S.A ' },
  { value: '095', label: '095 - BANCO CONFIDENCE DE CÂMBIO S.A  ' },
  { value: '094', label: '094 - BANCO FINAXIS ' },
  { value: '276', label: '276 - SENFF S.A ' },
  { value: '137', label: '137 - MULTIMONEY CC LTDA  ' },
  { value: '092', label: '092 - BRK S.A ' },
  { value: '047', label: '047 - BANCO BCO DO ESTADO DE SERGIPE S.A  ' },
  { value: '144', label: '144 - BEXS BANCO DE CAMBIO S.A. ' },
  { value: '126', label: '126 - BR PARTNERS BI  ' },
  { value: '301', label: '301 - BPP INSTITUIÇÃO DE PAGAMENTOS S.A ' },
  { value: '173', label: '173 - BRL TRUST DTVM SA ' },
  { value: '119', label: '119 - BANCO WESTERN UNION ' },
  { value: '254', label: '254 - PARANA BANCO S.A  ' },
  { value: '268', label: '268 - BARIGUI CH  ' },
  { value: '107', label: '107 - BANCO BOCOM BBM S.A ' },
  { value: '412', label: '412 - BANCO CAPITAL S.A ' },
  { value: '124', label: '124 - BANCO WOORI BANK DO BRASIL S.A  ' },
  { value: '149', label: '149 - FACTA S.A. CFI  ' },
  { value: '197', label: '197 - STONE PAGAMENTOS S.A  ' },
  { value: '142', label: '142 - BROKER BRASIL CC LTDA ' },
  { value: '389', label: '389 - BANCO MERCANTIL DO BRASIL S.A.  ' },
  { value: '184', label: '184 - BANCO ITAÚ BBA S.A  ' },
  { value: '634', label: '634 - BANCO TRIANGULO S.A (BANCO TRIÂNGULO) ' },
  { value: '545', label: '545 - SENSO CCVM S.A  ' },
  { value: '132', label: '132 - ICBC DO BRASIL BM S.A ' },
  { value: '298', label: '298 - VIPS CC LTDA  ' },
  { value: '129', label: '129 - UBS BRASIL BI S.A ' },
  { value: '128', label: '128 - MS BANK S.A BANCO DE CÂMBIO ' },
  { value: '194', label: '194 - PARMETAL DTVM LTDA  ' },
  { value: '310', label: '310 - VORTX DTVM LTDA ' },
  { value: '163', label: '163 - COMMERZBANK BRASIL S.A BANCO MÚLTIPLO ' },
  { value: '280', label: '280 - AVISTA S.A  ' },
  { value: '146', label: '146 - GUITTA CC LTDA  ' },
  { value: '279', label: '279 - CCR DE PRIMAVERA DO LESTE ' },
  { value: '182', label: '182 - DACASA FINANCEIRA S/A ' },
  { value: '278', label: '278 - GENIAL INVESTIMENTOS CVM S.A  ' },
  { value: '271', label: '271 - IB CCTVM LTDA ' },
  { value: '021', label: '021 - BANCO BANESTES S.A  ' },
  { value: '246', label: '246 - BANCO ABC BRASIL S.A  ' },
  { value: '751', label: '751 - SCOTIABANK BRASIL ' },
  { value: '208', label: '208 - BANCO BTG PACTUAL S.A ' },
  { value: '746', label: '746 - BANCO MODAL S.A ' },
  { value: '241', label: '241 - BANCO CLASSICO S.A  ' },
  { value: '612', label: '612 - BANCO GUANABARA S.A ' },
  { value: '604', label: '604 - BANCO INDUSTRIAL DO BRASIL S.A  ' },
  { value: '505', label: '505 - BANCO CREDIT SUISSE (BRL) S.A ' },
  { value: '196', label: '196 - BANCO FAIR CC S.A ' },
  { value: '300', label: '300 - BANCO LA NACION ARGENTINA ' },
  { value: '477', label: '477 - CITIBANK N.A  ' },
  { value: '266', label: '266 - BANCO CEDULA S.A  ' },
  { value: '122', label: '122 - BANCO BRADESCO BERJ S.A ' },
  { value: '376', label: '376 - BANCO J.P. MORGAN S.A ' },
  { value: '473', label: '473 - BANCO CAIXA GERAL BRASIL S.A  ' },
  { value: '745', label: '745 - BANCO CITIBANK S.A  ' },
  { value: '120', label: '120 - BANCO RODOBENS S.A  ' },
  { value: '265', label: '265 - BANCO FATOR S.A ' },
  { value: '007', label: '007 - BNDES (BANCO NACIONAL DO DESENVOLVIMENTO SOCIAL)  ' },
  { value: '188', label: '188 - ATIVA S.A INVESTIMENTOS ' },
  { value: '134', label: '134 - BGC LIQUIDEZ DTVM LTDA  ' },
  { value: '641', label: '641 - BANCO ALVORADA S.A  ' },
  { value: '029', label: '029 - BANCO ITAÚ CONSIGNADO S.A ' },
  { value: '243', label: '243 - BANCO MÁXIMA S.A  ' },
  { value: '078', label: '078 - HAITONG BI DO BRASIL S.A  ' },
  { value: '111', label: '111 - BANCO OLIVEIRA TRUST DTVM S.A ' },
  { value: '017', label: '017 - BNY MELLON BANCO S.A  ' },
  { value: '174', label: '174 - PERNAMBUCANAS FINANC S.A  ' },
  { value: '495', label: '495 - LA PROVINCIA BUENOS AIRES BANCO ' },
  { value: '125', label: '125 - BRASIL PLURAL S.A BANCO ' },
  { value: '488', label: '488 - JPMORGAN CHASE BANK ' },
  { value: '065', label: '065 - BANCO ANDBANK S.A ' },
  { value: '492', label: '492 - ING BANK N.V  ' },
  { value: '250', label: '250 - BANCO BCV ' },
  { value: '145', label: '145 - LEVYCAM CCV LTDA  ' },
  { value: '494', label: '494 - BANCO REP ORIENTAL URUGUAY  ' },
  { value: '253', label: '253 - BEXS CC S.A ' },
  { value: '269', label: '269 - HSBC BANCO DE INVESTIMENTO  ' },
  { value: '213', label: '213 - BCO ARBI S.A  ' },
  { value: '139', label: '139 - INTESA SANPAOLO BRASIL S.A  ' },
  { value: '018', label: '018 - BANCO TRICURY S.A ' },
  { value: '630', label: '630 - BANCO INTERCAP S.A  ' },
  { value: '224', label: '224 - BANCO FIBRA S.A ' },
  { value: '600', label: '600 - BANCO LUSO BRASILEIRO S.A ' },
  { value: '623', label: '623 - BANCO PAN ' },
  { value: '204', label: '204 - BANCO BRADESCO CARTOES S.A  ' },
  { value: '479', label: '479 - BANCO ITAUBANK S.A  ' },
  { value: '456', label: '456 - BANCO MUFG BRASIL S.A ' },
  { value: '464', label: '464 - BANCO SUMITOMO MITSUI BRASIL S.A  ' },
  { value: '613', label: '613 - OMNI BANCO S.A  ' },
  { value: '652', label: '652 - ITAÚ UNIBANCO HOLDING BM S.A  ' },
  { value: '653', label: '653 - BANCO INDUSVAL S.A  ' },
  { value: '069', label: '069 - BANCO CREFISA S.A ' },
  { value: '370', label: '370 - BANCO MIZUHO S.A  ' },
  { value: '249', label: '249 - BANCO INVESTCRED UNIBANCO S.A ' },
  { value: '318', label: '318 - BANCO BMG S.A ' },
  { value: '626', label: '626 - BANCO FICSA S.A ' },
  { value: '270', label: '270 - SAGITUR CC LTDA ' },
  { value: '366', label: '366 - BANCO SOCIETE GENERALE BRASIL ' },
  { value: '113', label: '113 - MAGLIANO S.A  ' },
  { value: '131', label: '131 - TULLETT PREBON BRASIL CVC LTDA  ' },
  { value: '011', label: '011 - C.SUISSE HEDGING-GRIFFO CV S.A (CREDIT SUISSE)  ' },
  { value: '611', label: '611 - BANCO PAULISTA  ' },
  { value: '755', label: '755 - BOFA MERRILL LYNCH BM S.A ' },
  { value: '089', label: '089 - CCR REG MOGIANA ' },
  { value: '643', label: '643 - BANCO PINE S.A  ' },
  { value: '140', label: '140 - EASYNVEST – TÍTULO CV S.A ' },
  { value: '707', label: '707 - BANCO DAYCOVAL S.A  ' },
  { value: '288', label: '288 - CAROL DTVM LTDA ' },
  { value: '101', label: '101 - RENASCENCA DTVM LTDA  ' },
  { value: '487', label: '487 - DEUTSCHE BANK S.A BANCO ALEMÃO  ' },
  { value: '233', label: '233 - BANCO CIFRA ' },
  { value: '177', label: '177 - GUIDE ' },
  { value: '633', label: '633 - BANCO RENDIMENTO S.A  ' },
  { value: '218', label: '218 - BANCO BS2 S.A ' },
  { value: '292', label: '292 - BS2 DISTRIBUIDORA DE TÍTULOS E INVESTIMENTOS  ' },
  { value: '169', label: '169 - BANCO OLÉ BONSUCESSO CONSIGNADO S.A ' },
  { value: '293', label: '293 - LASTRO RDV DTVM LTDA  ' },
  { value: '285', label: '285 - FRENTE CC LTDA  ' },
  { value: '080', label: '080 - B&T CC LTDA ' },
  { value: '753', label: '753 - NOVO BANCO CONTINENTAL S.A BM ' },
  { value: '222', label: '222 - BANCO CRÉDIT AGRICOLE BR S.A  ' },
  { value: '754', label: '754 - BANCO SISTEMA ' },
  { value: '098', label: '098 - CREDIALIANÇA CCR  ' },
  { value: '610', label: '610 - BANCO VR S.A  ' },
  { value: '712', label: '712 - BANCO OURINVEST S.A ' },
  { value: '010', label: '010 - CREDICOAMO  ' },
  { value: '283', label: '283 - RB CAPITAL INVESTIMENTOS DTVM LTDA  ' },
  { value: '217', label: '217 - BANCO JOHN DEERE S.A  ' },
  { value: '117', label: '117 - ADVANCED CC LTDA  ' },
  { value: '336', label: '336 - BANCO C6 S.A – C6 BANK  ' },
  { value: '654', label: '654 - BANCO DIGIMAIS S.A ' }
  ]

const DataContext = React.createContext(data);

export default DataContext;
