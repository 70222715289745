import React, { useContext, useEffect, useState, useRef } from "react";
import axios from "axios";
import { API_URL } from "utils/base-url";
import HostessAgencyForm from "views/HostessAgencies/Form";
import HostessAgencyContext, {
  data,
  initialState,
  labels,
} from "../../contexts/HostessAgencyContext";
import { confirm } from "components/Forms/DeleteConfirmation";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Table,
} from "react-bootstrap";

export default function HostessAgency(props) {
  const [objects, setObjects] = useState([]);
  const [state, setState] = useState(data);
  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(false);
  const [hostess, setHostess] = useState(props.hostess);
  const indexRoute = "hostess_agencies";
  const title = "Hostess/Agência";

  // *********************** MÉTODOS CRUD ************************* //

  const getObjects = async () => {
    try {
      const { data } = await axios.get(API_URL + "/" + indexRoute, {params: {hostess_id: hostess.id}});
      setObjects(data);
    } catch (e) {
      //dispatch({ type: "SET_ERROR", payload: "Something went wrong." });
      console.error(e);
    }
  };

  function handleEdit(app) {
    setShow(false);
    setState(app);
    setModal(true);
  }

  const handleShow = (app) => {
    setShow(true);
    setState(app);
    setModal(true);
  };

  const handleNew = () => {
    setShow(false);
    handleClear();
    setModal(true);
  };

  function handleClear() {
    setState(initialState);
  }

  const handleDelete = async (id) => {
    if (await confirm("Deseja realmente excluir o registro?")) {
      const result = { error: false };

      try {
        await axios.delete(`${API_URL}/${indexRoute}/${id}`);
        getObjects();
      } catch (e) {
        result.error = true;
        console.error(e);
      }

      return result;
    }
  };

  // *********************** MÉTODOS CRUD ************************* //

  useEffect(() => {
    getObjects();
  }, []);

  return (
    <>
      <HostessAgencyContext.Provider value={{ state, setState }}>
        <Card>
          <Card.Body>
            <Row style={{ marginTop: "10px" }}>
              <Col md="9">
                <Card.Title as="h4"> Hostess: {hostess.name}</Card.Title>
              </Col>
              <Col className="ml-auto" md="3">
                <div style={{ textAlign: "right", marginRight: "30px" }}>
                  <Button variant="default" onClick={handleNew}>
                    <i className="nc-icon nc-simple-add icon-bold" /> Vincular
                    Agência
                  </Button>
                </div>
              </Col>
              <HostessAgencyForm
                refresh={getObjects}
                setModal={setModal}
                show={show}
                handleClear={handleClear}
                modal={modal}
                hostess={hostess}
              ></HostessAgencyForm>
            </Row>

            <Row>
              <Col md="12">
                <Table className="table-hover table-striped w-full">
                  <thead>
                    <tr>
                      {labels &&
                        labels.map((label, i) => {
                          return <th key={i}>{label}</th>;
                        })}
                      <th className="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {objects &&
                      objects.map((data) => {
                        return (
                          <tr key={data.id}>
                            <td>{data.agency.name}</td>
                            <td>{data.code}</td>
                            <td>
                              {new Date(data.entry_date).toLocaleDateString(
                                "en-GB"
                              )}
                            </td>

                            <td className="td-actions text-center">
                              <OverlayTrigger
                                href="#"
                                onClick={(e) => handleShow(data)}
                                overlay={
                                  <Tooltip id="tooltip-48903503">
                                    View Profile..
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="btn-link btn-xs"
                                  href="#"
                                  onClick={(e) => handleShow(data)}
                                  variant="info"
                                >
                                  <i className="fas fa-user"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                href="#"
                                onClick={(e) => handleEdit(data)}
                                overlay={
                                  <Tooltip id="tooltip-981231696">
                                    Editar
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="btn-link btn-xs"
                                  href="#"
                                  onClick={(e) => handleEdit(data)}
                                  variant="success"
                                >
                                  <i className="fas fa-edit"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                href="#"
                                onClick={(e) => handleDelete(data.id)}
                                overlay={
                                  <Tooltip id="tooltip-255158527">
                                    Remover
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="btn-link btn-xs"
                                  href="#"
                                  onClick={() => handleDelete(data.id)}
                                  variant="danger"
                                >
                                  <i className="fas fa-times"></i>
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </HostessAgencyContext.Provider>
    </>
  );
}
