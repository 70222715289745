import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, InputGroup, Row, Col, Modal } from "react-bootstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { API_URL } from "utils/base-url";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import HostessAgencyContext, {
  humanizeAttributes,
} from "../../contexts/HostessAgencyContext";
import ErrorMessages from "components/Forms/ErrorMessages";

function newHostessAgency(props) {
  const hostessAgencyContext = useContext(HostessAgencyContext);
  const [errors, setErrors] = useState(null);
  const [agencies, setAgencies] = useState({});
  const refresh = props.refresh;
  const setModal = props.setModal;
  const handleClear = props.handleClear;
  const show = props.show;
  const hostess = props.hostess;
  /*if (hostess) {
    hostessAgencyContext.setState({
      ...hostessAgencyContext.state,
      hostess_id: hostess.id,
      hostess: hostess
    })
  }*/

  const handleClose = () => {
    setErrors(null);
    setModal(false);
  };
  const handleSubmit = () => {
    setErrors(null);
    axios
      .post(API_URL + "hostess_agencies", {
        hostess_agency: {
          ...hostessAgencyContext.state,
          hostess_id: hostess.id,
          hostess: hostess
        },
      })
      .then((resp) => handleResult())
      .catch((error) => {
        setErrors(error.response.data);
        return error;
      });
  };

  const handleUpdate = () => {
    setErrors(null);
    axios
      .put(API_URL + "hostess_agencies/" + hostessAgencyContext.state.id, {
        hostess_agency: {
          ...hostessAgencyContext.state,
          hostess_id: hostess.id,
          hostess: hostess
        },
      })
      .then((resp) => handleResult())
      .catch((error) => {
        setErrors(error.response.data);
        return error;
      });
  };

  function handleResult() {
    setErrors(null);
    refresh();
    handleClear();
    setModal(false);
  }

  const listAgencies = async () => {
    try {
      const { data } = await axios.get(API_URL + "/" + "agencies");
      setAgencies(data);
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    listAgencies();
  }, []);

  return (
    <>
      <Row></Row>
      <Modal show={props.modal} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nova Agência</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="HostessAgencyForm">
            <ErrorMessages
              errors={errors}
              humanizeAttributes={humanizeAttributes}
            ></ErrorMessages>
             <Form.Group>
              <label>Hostess</label>
              <Form.Control
                placeholder="Digite aqui..."
                type="text"
                disabled={true}
                value={hostess.name}                
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <label>Agência</label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                name="appId"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={hostessAgencyContext.state.agency}
                onChange={(e) =>
                  hostessAgencyContext.setState({
                    ...hostessAgencyContext.state,
                    agency_id: e.id,
                    agency: e
                  })
                }
                options={agencies}
                placeholder="Selecione a Agência"
              />
            </Form.Group>
            <Form.Group>
              <label>Código</label>
              <Form.Control
                placeholder="Digite aqui..."
                type="text"
                disabled={show}
                name="name"
                value={hostessAgencyContext.state.code}
                onChange={(e) =>
                  hostessAgencyContext.setState({
                    ...hostessAgencyContext.state,
                    code: e.target.value,
                  })
                }
              ></Form.Control>
            </Form.Group>     
            
                <Form.Group>
                <label>{humanizeAttributes["entry_date"]}</label>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Selecione a Data de Entrada",
                    }}
                    locale="pt-br"
                    value={hostessAgencyContext.state.entry_date && new Date(hostessAgencyContext.state.entry_date).toLocaleDateString('en-GB')}
                    closeOnSelect="true"
                    dateFormat="DD/MM/YYYY"
                    onChange={(e) =>
                      hostessAgencyContext.setState({
                        ...hostessAgencyContext.state,
                        entry_date: e,
                      })
                    }
                    timeFormat={false}
                  ></ReactDatetime>
                </Form.Group>     
          
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {!show && (
            <Button
              variant="primary"
              onClick={
                hostessAgencyContext.state.id === "" ? handleSubmit : handleUpdate
              }
            >
              {hostessAgencyContext.state.id === "" ? "Salvar" : "Atualizar"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default newHostessAgency;
