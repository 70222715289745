import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import { API_URL } from "utils/base-url";
import NotificationAlert from "react-notification-alert";
import moment from "moment";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Table,
} from "react-bootstrap";
import WeeklyStatementContext, {
  humanizeAttributes,
} from "../../contexts/WeeklyStatementContext";

function importDaily(props) {
  const weeklyStatementContext = useContext(WeeklyStatementContext);
  const [apps, setApps] = useState({});
  const [file, setFile] = useState();
  const [result, setResult] = useState([]);
  const notificationAlertRef = React.useRef(null);
  const [dailyReport, setDailyReport] = useState({
    weekly_statement: weeklyStatementContext.state,
    date: moment(),
  });
  const getDailyReports = props.getDailyReports;
  const app_id = props.app_id;



  var yesterday = moment().subtract(1, "day");
  var valid = function (current) {
    return current.isAfter(yesterday);
  };

  const listApps = async () => {
    try {
      const { data } = await axios.get(API_URL + "/" + "apps", {
        params: { app_id: app_id }
      });
      setApps(data);
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = () => {
    setResult([]);
    var error = null;
    setDailyReport({
      ...dailyReport,
      app_id: app_id
    });
    if (!weeklyStatementContext.state.id) {
      handleMsg("Selecione a Semana", "danger");
    } else if (!dailyReport.app_id) {
      handleMsg("Selecione o Aplicativo", "danger");
    } else if (!dailyReport.date) {
      handleMsg("Selecione a data", "danger");
      1;
    } else if (
      dailyReport.date <
        moment(dailyReport.weekly_statement.start_date, "AAAA-MM-DD") ||
      dailyReport.date >
        moment(dailyReport.weekly_statement.end_date, "AAAA-MM-DD")
    ) {
      handleMsg("Data Selecionada não está contida na semana", "danger");
    } else if (!file) {
      handleMsg("Arquivo não selecionado", "danger");
    } else {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("app_id", dailyReport.app_id);
      formData.append("date", dailyReport.date);
      formData.append("weekly_statement_id", weeklyStatementContext.state.id);

      axios
        .post(API_URL + "/daily_reports/daily_import", formData)
        .then((resp) => handleResult(resp))
        .catch((error) => {
          handleMsg(error.data.result.toString(), "danger");
          return error;
        });
    }
  };

  function handleResult(resp) {
    if (resp.data.success) {
      setResult(resp.data.result);
      handleMsg("Arquivo Importado com Sucesso!", "info");
      getDailyReports(weeklyStatementContext.state.id);
    } else {
      handleMsg(resp.data.result.toString(), "danger");
    }
  }

  function handleMsg(msg, type) {
    notificationAlertRef.current.notificationAlert({
      place: "tr",
      message: (
        <div>
          <div style={{ margin: "10px" }}>{msg}</div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    });
  }

  useEffect(() => {
    listApps();
  }, []);

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Card className="card-stats">
        <Card.Header>
          <Card.Title as="h4">
            Importar Relatório Diário:{" "}
            {weeklyStatementContext.state &&
              weeklyStatementContext.state.week_name}
          </Card.Title>
        </Card.Header>
        <hr></hr>
        <Card.Body>
          <Row>
            <Col xs="2">
              <div className="icon-big text-center icon-success">
                <i className="nc-icon nc-bullet-list-67 text-success"></i>
              </div>
            </Col>
            <Col xs="10">
              <Form id="ImportDailyForm">
                <Row>
                  <Col xs="6">
                    <Form.Group>
                      <label>Aplicativo</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="appId"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        onChange={(e) =>
                          setDailyReport({
                            ...dailyReport,
                            app_id: e.id,
                            app: e,
                          })
                        }
                        options={apps}
                        placeholder="Selecione o Aplicativo"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="6">
                    <label>Data do Arquivo</label>
                    <Form.Group>
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Selecione a Data do Arquivo",
                        }}
                        locale="pt-br"
                        value={
                          dailyReport.date &&
                          new Date(dailyReport.date).toLocaleDateString("en-GB")
                        }
                        closeOnSelect="true"
                        dateFormat="DD/MM/YYYY"
                        onChange={(e) =>
                          setDailyReport({
                            ...dailyReport,
                            date: e,
                          })
                        }
                        timeFormat={false}
                      ></ReactDatetime>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Arquivo</Form.Label>
                  <Form.Control type="file" onChange={handleFileChange} />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col xs="2"></Col>
            <Col xs="10">
              <Button variant="primary" onClick={handleSubmit}>
                Importar
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      {result.length > 0 ? (
        <>
          <Card>
            <Card.Header>
              <Card.Title as="h4">Não importados</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs="12">
                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>Código</th>
                        <th>Hostess</th>                       
                        <th>Total Video Call</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result.map((data) => {
                          return (
                            <tr key={data.code}>
                              <td>{data.code}</td>
                              <td>{data.anchor_name}</td>
                              <td>{data.video_call_income}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
export default importDaily;
