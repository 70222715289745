import React from "react";
import axios from "axios";
import { API_URL } from "utils/base-url";

export const data = {
  id: "",
  username: "",
  email: "",
  isLoggedIn: false,
  user: "",
  errors: ""
};

export const humanizeAttributes = {
  email: 'Email',
  username: 'Usuário',
  Password: 'Senha'
}

export const initialState = data;

export const labels = ["Usuário", "Email"];

const DataContext = React.createContext(data);

export default DataContext;
