import React from "react";
import axios from "axios";
import { API_URL } from "utils/base-url";

export const data = {
  id: "",
  name: "",
  operational_system: "",
  contact_name: "",
  contact_phone: "",
};

export const initialState = data;

export const labels = ["Aplicativo", "S.O", "Responsável", "Fone"];

export const dataOs = [
  {
    value: "",
    label: "Sistema Operacional",
    isDisabled: true,
  },
  { value: "android", label: "Android" },
  { value: "ios", label: "IOS" },
  { value: "ambos", label: "Ambos" },
];

export const humanizeAttributes = {
  name: 'Aplicativo',
  operational_system: 'S.O',
  contact_name: 'Responsável',
  contact_phone: 'Fone'
}

const DataContext = React.createContext(data);

export default DataContext;
