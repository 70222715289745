import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { API_URL } from "utils/base-url";
import axios from "axios";
import UserContext, {
  data,
  initialState,
  labels,
} from "../contexts/UserContext";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Col,
} from "react-bootstrap";

function LoginPage(props) {
  const userContext = useContext(UserContext);
  const [password, setPassword] = useState(null);
  const history = useHistory();
  const handleLogin = props.handleLogin;


  const [cardClasses, setCardClasses] = React.useState("card-hidden");
  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    debugger;
    axios
      .post(API_URL + "login", {
        user: {
          username: userContext.state.username,
          password: password,
        },
      })
      .then((response) => {
        if (response.data.logged_in) {
          userContext.setState({
            ...userContext.state,
            id: response.data.id,
            email: response.data.email,
            user: response.data,
            isLoggedIn: true,
            errors: null
          });
          handleLogin(response.data);
          history.push("/admin");
        } else {
          userContext.setState({
            ...userContext.state,
            errors: response.data.errors,
          });
        }
      })
      .catch((error) => {
        console.log("api errors:", error);
        return error;
      });
  };

  return (
    <>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("assets/img/full-screen-image-2.jpg")}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8">
              <Form className="form" onSubmit={handleSubmit}>
                <Card className={"card-login " + cardClasses}>
                  <Card.Header>
                    <h3 className="header text-center">Login</h3>
                  </Card.Header>
                  <Card.Body>
                    <Card.Body>
                      <Form.Group>
                        <label>Usuário</label>
                        <Form.Control
                          placeholder="Digite o Usuário"
                          onChange={(e) =>
                            userContext.setState({
                              ...userContext.state,
                              username: e.target.value,
                            })
                          }
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <label>Password</label>
                        <Form.Control
                          placeholder="Password"
                          type="password"
                          onChange={(e) =>
                            setPassword(e.target.value)
                          }
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card.Body>
                  <Card.Footer className="ml-auto mr-auto">
                    <Button className="btn-wd" type="submit" variant="warning">
                      Login
                    </Button>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" + require("assets/img/full-screen-image-2.jpg") + ")",
          }}
        ></div>
      </div>
    </>
  );
}

export default LoginPage;
